import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useGLTF, Environment, OrbitControls } from "@react-three/drei";
import { TextureLoader } from 'three';
var Model1 = function () {
    var textureLoader = new TextureLoader();
    var leafTexture = textureLoader.load("/textures/normal_leaves_diffuse.png");
    var trunkTexture = textureLoader.load("/textures/trunk_diffuse.png");
    var moonTexture = textureLoader.load("/textures/moon.jpg");
    var grassTexture = textureLoader.load("/textures/grass.jpg");
    // const gltf = useLoader(GLTFLoader, "./realistic_tree.gltf");
    var gltf = useGLTF("/realistic_tree.glb");
    // const grass = useGLTF("/grass.glb")
    var myMesh = React.useRef(null);
    useEffect(function () {
        gltf.scene.traverse(function (child) {
            if (child.isMesh) {
                var mesh = child;
                // Set texture for the tree trunk
                if (mesh.name === "trunk_low_trunk_0" || mesh.name === "br_low_trunk_0") {
                    var material = mesh.material;
                    material.map = trunkTexture;
                }
                // Apply the leaf texture to the leaves
                else if (mesh.name === "crown_merged_normal_leaves_0") {
                    var material = mesh.material;
                    material.map = leafTexture;
                    material.needsUpdate = true; // Ensure the material updates
                }
            }
        });
    }, [gltf, leafTexture, trunkTexture]);
    // Rotate the tree
    // useFrame((_, delta) => {
    //     myMesh.current.rotation.y += 0.3 * delta
    // })
    return (_jsxs(_Fragment, { children: [_jsx("ambientLight", { intensity: 1.5 }), _jsx("directionalLight", { position: [0, 10, 10], intensity: 3 }), _jsx("mesh", { ref: myMesh, scale: 0.3, position: [0, -2, 0], children: _jsx("primitive", { object: gltf.scene }) }), _jsx(OrbitControls, { maxPolarAngle: Math.PI / 2 }), _jsx(Environment, { background: true, files: "/textures/stars.jpg" }), _jsx(Environment, { preset: "forest" }), _jsxs("mesh", { receiveShadow: true, scale: 10, position: [0, 100, 200], children: [_jsx("sphereGeometry", { args: [1, 64, 64] }), _jsx("meshStandardMaterial", { attach: "material", map: moonTexture, needsUpdate: true })] }), _jsxs("mesh", { receiveShadow: true, position: [0, -2, 0], children: [_jsx("boxGeometry", { args: [100, 0, 100] }), _jsx("meshStandardMaterial", { attach: "material", map: trunkTexture, needsUpdate: true })] })] }));
};
export var TreeEnvironment = function () {
    return (_jsx(_Fragment, { children: _jsx(Model1, {}) }));
};
