var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
var customIconButtonStyle = {
    // backgroundColor: "rgb(3, 27, 78)",
    margin: "20px 10px 10px 10px",
    border: "solid 1px black",
    textDecoration: "none",
    boxShadow: "none",
    borderRadius: "4px",
    width: "150px",
    color: "rgb(3, 27, 78)",
    fontWeight: "bold",
    // float: "right",
};
import { SkillTreeGroup, SkillTree as SkillTreeUI, } from 'beautiful-skill-tree';
import BasicModal from '../../../../components/modal/modal';
import { CreateSkill } from '../../../../components/createSkill/createSkill';
import { TreeNodeContent } from '../TreeNodeContent/TreeNodeContent';
var skillTheme = {
    border: "solid 1px black",
    // primaryFont: "Arial",
    // primaryFontColor: "white",
    treeBackgroundColor: "#8499B1",
    disabledTreeOpacity: 0.5,
    // headingFont: "Arial",
    // headingFontColor: "black",
    nodeActiveBackgroundColor: "rgb(3, 27, 78)",
    nodeAlternativeActiveBackgroundColor: "#A5C4D4",
    nodeBorderColor: "black", // Change to white
    // nodeBackgroundColor: "white",
    // tooltipFontColor: "rgb(3, 27, 78)",
    // tooltipBackgroundColor: "red",
    // nodeAlternativeActiveBackgroundColor: "rgb(3, 27, 78)",
    // nodeOverlayColor: "rgb(3, 27, 78)",
    nodeHoverBorder: "solid 1px black",
    headingHoverColor: "rgb(3, 27, 78)",
    // nodeBackgroundColor: "rgb(3, 27, 78)",
    // nodeHoverBorderColor: "white",
    edgeBorder: "solid 0px black",
};
var Tree = function (_a) {
    var tree = _a.tree, skillTrees = _a.skillTrees, setSkillTrees = _a.setSkillTrees;
    // TODO: Make a separate search page which also handles the results list
    // TODO: You will need to update the skills data structure to be a map of skillTreeId to skills, it wont work otherwise !!!
    var _b = useState(false), modal = _b[0], setModal = _b[1];
    var _c = useState(undefined), currSkill = _c[0], setCurrSkill = _c[1];
    var updateSkillTreeWithModal = function (skillId) {
        if (skillId) {
            setCurrSkill(skillId);
        }
        setModal(!modal);
    };
    var buildSkillTree = function (skills) {
        var skillMap = {};
        var roots = [];
        if (!skills || skills.length === 0) {
            return [];
        }
        // Initialize skillMap and separate roots (skills without parentId)
        skills.forEach(function (skill) {
            skillMap[skill.id] = __assign(__assign({}, skill), { children: [] });
            if (!skill.parentId) {
                roots.push(skillMap[skill.id]);
            }
        });
        // Populate children
        skills.forEach(function (skill) {
            if (skill.parentId && skillMap[skill.parentId]) {
                skillMap[skill.parentId].children.push(skillMap[skill.id]);
            }
        });
        // Convert SkillWithChildren to SkillType
        var convertToSkillType = function (skill) { return ({
            id: skill.id,
            title: skill.name,
            tooltip: {
                content: _jsx(TreeNodeContent, { skill: skill, updateSkillTreeWithModal: updateSkillTreeWithModal, skillTrees: skillTrees, setSkillTrees: setSkillTrees }),
            },
            children: skill.children.map(convertToSkillType),
        }); };
        return roots.map(convertToSkillType);
    };
    // onClick: () => signOut(setUser, setJobs)
    return (_jsxs("div", { className: "skill-tree", children: [!tree.Skill || tree.Skill.length === 0 ? _jsx("p", { children: "No trees found  " })
                :
                    _jsx(SkillTreeGroup, { theme: skillTheme, children: function (_a) {
                            var skillCount = _a.skillCount;
                            return (_jsx(SkillTreeUI, { treeId: tree.id, title: tree.name, 
                                // data={tree.Skill}
                                data: buildSkillTree(tree.Skill), collapsible: true, description: tree.description }));
                        } }), _jsx(BasicModal, { title: "Create Skill", content: _jsx(CreateSkill, { setShowModal: setModal, skillTreeId: tree.id, skillTrees: skillTrees, setSkillTrees: setSkillTrees, parentSkill: currSkill }), open: modal, setOpen: setModal })] }));
};
export default Tree;
