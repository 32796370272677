import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect } from 'react';
import { getData } from "../../utils/api.util";
import { Toaster } from 'react-hot-toast';
import { IconButton } from "../../components/iconButton/iconButton";
import GoogleIcon from '@mui/icons-material/Google';
import { authWithGoogle, signOut } from "../../utils/firebase.util";
import { authUserAtom, skillTreesAtom } from "../../atoms";
import { useAtom } from 'jotai/react';
import { Divider } from '@mui/material';
import "./App.css";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { CreateSkillTree } from "../../components/createSkillTree/createSkillTree";
import MuiIconButton from '@mui/material/IconButton';
import BasicModal from "../../components/modal/modal";
import Avatar from '@mui/material/Avatar';
import MenuItems from '../../components/menuItems/menuItems';
import skillsTreeLogo from '../../assets/skills-tree.png';
import Tree from './components/Tree/Tree';
var customIconButtonStyle = {
    // backgroundColor: "rgb(3, 27, 78)",
    margin: "20px 10px 10px 10px",
    border: "solid 1px black",
    textDecoration: "none",
    boxShadow: "none",
    borderRadius: "4px",
    width: "150px",
    color: "rgb(3, 27, 78)",
    fontWeight: "bold",
    // float: "right",
};
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SkillProvider, } from 'beautiful-skill-tree';
import { TreeEnvironment } from './components/threejs/3DTree';
import { Canvas } from '@react-three/fiber';
var skillTheme = {
    border: "solid 1px black",
    // primaryFont: "Arial",
    // primaryFontColor: "white",
    // treeBackgroundColor: "white",
    disabledTreeOpacity: 0.5,
    // headingFont: "Arial",
    // headingFontColor: "black",
    nodeActiveBackgroundColor: "rgb(3, 27, 78)",
    nodeBorderColor: "black", // Change to white
    // nodeBackgroundColor: "white",
    // tooltipFontColor: "rgb(3, 27, 78)",
    // tooltipBackgroundColor: "red",
    // nodeAlternativeActiveBackgroundColor: "rgb(3, 27, 78)",
    // nodeOverlayColor: "rgb(3, 27, 78)",
    nodeHoverBorder: "solid 1px black",
    headingHoverColor: "rgb(3, 27, 78)",
    // nodeBackgroundColor: "rgb(3, 27, 78)",
    // nodeHoverBorderColor: "white",
    edgeBorder: "solid 0px black",
};
function App() {
    // TODO: Make a separate search page which also handles the results list
    // Import userAtom from the atoms.ts file
    var _a = useAtom(authUserAtom), user = _a[0], setUser = _a[1];
    var _b = React.useState(false), openSkillTreeModal = _b[0], setSkillTreeOpenModal = _b[1];
    // Use atom to keep track and set skillTrees:
    var _c = useAtom(skillTreesAtom), skillTrees = _c[0], setSkillTrees = _c[1];
    // Dropdown state:
    var _d = React.useState(null), anchorEl = _d[0], setAnchorEl = _d[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    // Create a use effect to grab the skill trees from the database
    useEffect(function () {
        if (user.refreshToken)
            getData(user.refreshToken, "/skill-trees/user/".concat(user.id), setSkillTrees, null, setUser);
    }, [user.refreshToken]);
    // TODO: You will need to update the skills data structure to be a map of skillTreeId to skills, it wont work otherwise !!!
    var userDropdownItems = [
        {
            label: "Sign Out",
            icon: _jsx(LogoutIcon, {}),
            onClick: function () { return signOut(setUser, null); }
        },
        {
            label: "Profile",
            icon: _jsx(AccountBoxIcon, {}),
            // Direct to data.halalscout.com.au/profile
            onClick: function () { return window.open("https://data.halalscout.com.au/profile", "_blank"); }
        }
    ];
    // onClick: () => signOut(setUser, setJobs)
    return (_jsxs("div", { className: "App", children: [_jsxs("header", { className: "App-header", children: [_jsxs("div", { className: "profile-heading", style: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [_jsx("img", { style: { maxWidth: "100%", maxHeight: "100%" }, src: skillsTreeLogo, height: 40, width: 200, className: "App-logo", alt: "logo" }), user.id ?
                                _jsxs("div", { className: "menu-section", children: [_jsx(IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: function () { return setSkillTreeOpenModal(!openSkillTreeModal); }, content: "Create Tree", icon: _jsx(AddCircleOutlineIcon, {}) }), _jsx(MenuItems, { items: userDropdownItems, open: open, handleClose: handleClose, anchorEl: anchorEl }), _jsx(MuiIconButton, { onClick: handleClick, style: {
                                                marginRight: "10px",
                                            }, children: _jsx(Avatar, { "aria-controls": open ? 'dropdown-menu' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, alt: user.name, src: "".concat(user.photoURL, "?_=").concat(+new Date()), style: {
                                                    width: "40px",
                                                    height: "40px",
                                                } }) })] })
                                :
                                    _jsx(IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: function () { return authWithGoogle(setUser); }, content: "Sign In", icon: _jsx(GoogleIcon, {}) })] }), _jsx(Divider, { variant: "middle", style: { marginTop: "10px" } }), user.id ?
                        _jsx(SkillProvider, { children: skillTrees === null || skillTrees === void 0 ? void 0 : skillTrees.map(function (tree) {
                                return (_jsx(Tree, { tree: tree, setSkillTrees: setSkillTrees, skillTrees: skillTrees }));
                            }) }) :
                        // TODO (Abz): Replace this with a three.js component
                        _jsxs("div", { className: "header-login", children: [_jsx("h1", { className: 'sign-in-heading', children: "Sign in to view your skills tree" }), _jsx("div", { className: "login-section", style: { display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }, children: _jsx(Canvas, { camera: { position: [0, 0, 3] }, children: _jsx(Suspense, { fallback: null, children: _jsx(TreeEnvironment, {}) }) }) })] }), _jsx(BasicModal, { title: "Create Tree", content: _jsx(CreateSkillTree, { setShowModal: setSkillTreeOpenModal, skillTrees: skillTrees, setSkillTrees: setSkillTrees }), open: openSkillTreeModal, setOpen: setSkillTreeOpenModal })] }), _jsx(Toaster, {})] }));
}
export default App;
