var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { FormControl, useFormControlContext } from '@mui/base/FormControl';
import { Input, inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';
export default function BasicFormControl(_a) {
    var value = _a.value, required = _a.required, setUpdatedValue = _a.setUpdatedValue, width = _a.width;
    var onChange = function (e) {
        setUpdatedValue && setUpdatedValue(e.target.value);
    };
    return (_jsxs(FormControl, { value: value, required: required, onChange: onChange, children: [_jsx(StyledInput, { width: width ? width : "220px" }), _jsx(HelperText, {})] }));
}
var StyledInput = styled(Input)(function (_a) {
    var theme = _a.theme, width = _a.width;
    return "\n\n  .".concat(inputClasses.input, " {\n    width: ").concat(width, ";\n    font-family: 'IBM Plex Sans', sans-serif;\n    font-size: 0.875rem;\n    font-weight: 400;\n    line-height: 1.5;\n    padding: 8px 12px;\n    border-radius: 4px;\n    color: ").concat(theme.palette.mode === 'dark' ? grey[300] : grey[900], ";\n    background: ").concat(theme.palette.mode === 'dark' ? grey[900] : '#fff', ";\n    border: 1px solid ").concat(theme.palette.mode === 'dark' ? grey[700] : grey[200], ";\n    box-shadow: 0px 2px 2px ").concat(theme.palette.mode === 'dark' ? grey[900] : grey[50], ";\n\n    &:hover {\n      border-color: rgb(3, 27, 78);\n    }\n\n    &:focus {\n    //   box-shadow: 0 0 0 3px ").concat(theme.palette.mode === 'dark' ? "rgb(3, 27, 78)" : "rgb(3, 27, 78)", ";\n   border: 2px dotted rgb(3, 27, 78);\n    outline: 1px solid rgb(3, 27, 78)\n\n    }\n  }\n");
});
var HelperText = styled(function (props) {
    var formControlContext = useFormControlContext();
    var _a = React.useState(false), dirty = _a[0], setDirty = _a[1];
    React.useEffect(function () {
        if (formControlContext === null || formControlContext === void 0 ? void 0 : formControlContext.filled) {
            setDirty(true);
        }
    }, [formControlContext]);
    if (formControlContext === undefined) {
        return null;
    }
    var required = formControlContext.required, filled = formControlContext.filled;
    var showRequiredError = dirty && required && !filled;
    return showRequiredError ? _jsx("p", __assign({}, props, { children: "This field is required." })) : null;
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: 'IBM Plex Sans', sans-serif;\n  font-size: 0.875rem;\n"], ["\n  font-family: 'IBM Plex Sans', sans-serif;\n  font-size: 0.875rem;\n"])));
var grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
var templateObject_1;
