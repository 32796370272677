var _a;
import { atomWithStorage } from "jotai/utils";
// TODO: Sometimes this doesnt work, localStorage might be better
export var authTokenAtom = atomWithStorage("authToken", ((_a = localStorage.getItem("authToken")) === null || _a === void 0 ? void 0 : _a.replace(/(^"|"$)/g, "")) || "");
export var authUserAtom = atomWithStorage("authUser", JSON.parse(localStorage.getItem("authUser") || "{}"));
export var userProjectsAtom = atomWithStorage("userProjects", JSON.parse(localStorage.getItem("userProjects") || "{}"));
export var isLoadingAtom = atomWithStorage("isLoading", false);
export var systemChartsAtom = atomWithStorage("systemCharts", JSON.parse(localStorage.getItem("systemCharts") || "[]"));
export var userJobsAtom = atomWithStorage("userJobs", JSON.parse(localStorage.getItem("userJobs") || "{}"));
// SkillTrees
export var skillTreesAtom = atomWithStorage("skillTrees", JSON.parse(localStorage.getItem("skillTrees") || "[]"));
