var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { authUserAtom } from "../../atoms";
import { useAtom } from "jotai";
import BasicFormControl from "../inputForm/inputForm";
import { IconButton } from "../iconButton/iconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BlockIcon from '@mui/icons-material/Block';
import { Divider } from "@mui/material";
import { postData } from "../../utils/api.util";
import toast from "react-hot-toast";
var customIconButtonStyle = {
    backgroundColor: "rgb(3, 27, 78)",
    border: "solid 1px white",
    textDecoration: "none",
    boxShadow: "none",
    borderRadius: "4px",
    minWidth: "200px",
    color: "white",
    fontWeight: "bold",
};
var FORM_WIDTH = "95%";
export var CreateSkillTree = function (_a) {
    var setShowModal = _a.setShowModal, skillTrees = _a.skillTrees, setSkillTrees = _a.setSkillTrees;
    var _b = useAtom(authUserAtom), user = _b[0], setUser = _b[1];
    var _c = React.useState({ userId: user.id }), newSkillTree = _c[0], setNewSkillTree = _c[1];
    var _d = React.useState({ userId: user.id }), rootSkill = _d[0], setRootSkill = _d[1];
    var setName = function (value) {
        setNewSkillTree(__assign(__assign({}, newSkillTree), { name: value }));
    };
    var setDescription = function (value) {
        setNewSkillTree(__assign(__assign({}, newSkillTree), { description: value }));
    };
    var setRootSkillName = function (value) {
        setRootSkill(__assign(__assign({}, rootSkill), { name: value }));
    };
    var setRootSkillDescription = function (value) {
        setRootSkill(__assign(__assign({}, rootSkill), { description: value }));
    };
    var onSubmit = function () {
        if (!newSkillTree.name) {
            toast.error("Name is required");
            return;
        }
        // TODO: In the same func you wanna create a root skill otherwse we have glitches !!
        postData(user.refreshToken, "/skill-tree", newSkillTree, "Created Skill Tree", setUser).then(function (dbTree) {
            // We dont close modal since we need a root skill to be created
            // setShowModal(false)
            // Create the skill post req
            postData(user.refreshToken, "/skill", __assign(__assign({}, rootSkill), { skillTreeId: dbTree.id }), "Created Root Skill", setUser).then(function (res) {
                var newSkillTree = dbTree;
                // consider if newSkillTree.Skill is also undefined or empty
                if (newSkillTree) {
                    newSkillTree.Skill = newSkillTree.Skill ? __spreadArray(__spreadArray([], newSkillTree.Skill, true), [res], false) : [res];
                    setSkillTrees(__spreadArray(__spreadArray([], skillTrees.filter(function (st) { return st.id !== res.skillTreeId; }), true), [newSkillTree], false));
                }
            });
            // setSkillTrees([...skillTrees, res])
        })
            .catch(function (err) {
            console.log(err);
            toast.error(err.message);
        });
        setShowModal(false);
        // 
    };
    return (_jsxs("div", { className: "create-skill-tree-container", children: [_jsx("p", { className: "edit-dropdown-label", children: " Name " }), _jsx(BasicFormControl, { width: FORM_WIDTH, value: newSkillTree.name, required: true, setUpdatedValue: setName }), _jsx("p", { className: "edit-dropdown-label", children: " Description " }), _jsx(BasicFormControl, { width: FORM_WIDTH, value: newSkillTree.description, required: true, setUpdatedValue: setDescription }), _jsx("h4", { children: " Create Root Skill" }), _jsx("p", { className: "edit-dropdown-label", children: " Name " }), _jsx(BasicFormControl, { width: FORM_WIDTH, value: rootSkill.name, required: true, setUpdatedValue: setRootSkillName }), _jsx("p", { className: "edit-dropdown-label", children: " Description " }), _jsx(BasicFormControl, { width: FORM_WIDTH, value: rootSkill.description, required: true, setUpdatedValue: setRootSkillDescription }), _jsx(Divider, { variant: "middle", style: { color: "white", border: "solid 0.1rem", paddingBottom: "10px" } }), _jsxs("div", { className: "create-skill-tree-buttons", style: { display: "flex", gap: "20px", flexWrap: "wrap" }, children: [_jsx(IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: onSubmit, content: "Create Skill Tree", icon: _jsx(AddCircleOutlineIcon, {}) }), _jsx(IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: function () { return setNewSkillTree({}); }, content: "Clear", icon: _jsx(BlockIcon, {}) })] })] }));
};
