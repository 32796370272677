import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// https://mui.com/joy-ui/react-modal/
import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
var modalStyle = {
    // overflow: 'visible',
    display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '10vh',
    overflowY: 'scroll',
    position: 'auto',
};
export default function BasicModal(_a) {
    var title = _a.title, content = _a.content, open = _a.open, setOpen = _a.setOpen;
    return (_jsx(React.Fragment, { children: _jsx(Modal, { "aria-labelledby": "modal-title", "aria-describedby": "modal-desc", open: open, onClose: function () { return setOpen(false); }, sx: modalStyle, children: _jsxs(Sheet, { variant: "outlined", sx: {
                    maxWidth: 1000,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                    overflow: 'visible',
                }, children: [_jsx(ModalClose, { variant: "plain", sx: { m: 1 } }), _jsx(Typography, { component: "h2", id: "modal-title", level: "h4", textColor: "inherit", fontWeight: "lg", mb: 1, children: title }), _jsx("div", { id: "modal-desc", children: content })] }) }) }));
}
