var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAuth, signInWithPopup, GoogleAuthProvider, } from "firebase/auth";
import toast from "react-hot-toast";
import firebaseConfig from "../portal/src/config";
import { initializeApp } from "firebase/app";
import { postData, putData } from "./api.util";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
var app = initializeApp(firebaseConfig);
var PROVIDER = new GoogleAuthProvider();
var AUTH = getAuth(app);
var storage = getStorage(app);
var getGoogleRefreshToken = function (auth, provider) { return __awaiter(void 0, void 0, void 0, function () {
    var user, googleToken;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                user = AUTH.currentUser;
                return [4 /*yield*/, (user === null || user === void 0 ? void 0 : user.getIdToken())];
            case 1:
                googleToken = _a.sent();
                return [2 /*return*/, googleToken];
        }
    });
}); };
var getGoogleUserFromPopup = function (auth, provider) { return __awaiter(void 0, void 0, Promise, function () {
    var googleUser;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, signInWithPopup(auth, provider)
                    .then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                    var token, user;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, result.user.getIdToken()];
                            case 1:
                                token = _a.sent();
                                // 
                                result.user.photoURL;
                                user = {
                                    name: result.user.displayName,
                                    email: result.user.email,
                                    signInMethod: result.user.providerId,
                                    refreshToken: token,
                                    photoURL: result.user.photoURL,
                                    uid: result.user.uid,
                                };
                                // The signed-in user info.
                                return [2 /*return*/, user];
                        }
                    });
                }); })
                    .catch(function (error) {
                    throw error;
                    // ...
                })];
            case 1:
                googleUser = _a.sent();
                if (googleUser === undefined) {
                    throw new Error("Unable to sign in");
                }
                return [2 /*return*/, googleUser];
        }
    });
}); };
export var authWithGoogle = function (setUser) {
    getGoogleUserFromPopup(AUTH, PROVIDER)
        .then(function (user) { return __awaiter(void 0, void 0, void 0, function () {
        var scraperUser, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, postData(user.refreshToken, "/user/login", user, "Successfully signed in", setUser)];
                case 1:
                    scraperUser = _a.sent();
                    setUser(scraperUser);
                    // TODO: Refreshes of the page remove setTimeout and it never runs; please fix this
                    setTimeout(function () {
                        console.log("Signing out user");
                        // TODO: Give the user the option of staying signed in i.e. extend the session
                        signOut(setUser, null);
                        // TODO: Do alot of testing for this since we don't clear any timeouts upon sign out
                    }, (1000 * 60 * 60));
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    throw error_1;
                case 3: return [2 /*return*/];
            }
        });
    }); })
        .catch(function (err) { return toast.error("Failed to sign in", { icon: "🔥" }); });
};
export var signIn = function () {
};
// TODO: This doesnt work and just gives error:
/**
 * error: id token is not issued by Google. (auth/invalid-credential).
 */
export var verifyUser = function (user) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/];
    });
}); };
export var signOut = function (setUser, setJobs) {
    if (setJobs === void 0) { setJobs = null; }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, AUTH.signOut().then(function () {
                        toast.success("Signed out");
                        setUser({});
                        if (setJobs)
                            setJobs([]);
                        // Clear all timeouts
                    }).catch(function (error) {
                        toast.error("Failed to sign out");
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
};
export var uploadPhotoToFirebase = function (user, base64ia, setUser) { return __awaiter(void 0, void 0, void 0, function () {
    var relativePath, userImagesRef, photoURLFull;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                relativePath = "/public/users/profile_pics/".concat(user.id, ".jpg");
                userImagesRef = ref(storage, relativePath);
                return [4 /*yield*/, uploadBytes(userImagesRef, base64ia).then(function (snapshot) {
                        console.log(snapshot.ref.fullPath);
                    })
                    // Get the reference URL
                ];
            case 1:
                _a.sent();
                return [4 /*yield*/, getDownloadURL(userImagesRef)];
            case 2:
                photoURLFull = _a.sent();
                // Get reference url to image
                console.log(photoURLFull);
                return [4 /*yield*/, putData(user.refreshToken, "/user/".concat(user.id), { photoURL: photoURLFull }, "Successfully updated photo", setUser).then(function (res) {
                        setUser(res);
                    }).catch(function (err) {
                        toast.error(err.message);
                    })];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
