import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import "./button.css";
// TODO: For some reason the imported styles isn't being applied
var style = {
    backgroundColor: "transparent",
    // border: "solid 1px white",
    // borderRadius: "0.5rem",
    color: "white",
    textDecoration: "none",
    fontSize: "15px",
    boxShadow: "none",
    padding: "10px 10% 10px 10%",
    fontWeight: "bold",
    // TODO: Below doesnt work
};
import Button from '@mui/material/Button';
export var LinkButton = function (_a) {
    var content = _a.content, icon = _a.icon, onClick = _a.onClick, className = _a.className, overrideStyle = _a.overrideStyle;
    return (_jsx("div", { className: className ? className : "custom-button-container", children: _jsx(Button, { style: overrideStyle ? overrideStyle : style, className: "custom-button", component: "label", startIcon: icon, onClick: onClick, children: content }) }));
};
