import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
export default function MenuItems(props) {
    var itemClick = function (onClick) {
        onClick();
        props.handleClose();
    };
    return (_jsx("div", { children: _jsx(Menu, { id: "dropdown-menu", MenuListProps: {
                'aria-labelledby': 'dropdown-button',
            }, anchorEl: props.anchorEl, open: props.open, onClose: props.handleClose, children: props.items.map(function (item, index) {
                return (_jsxs(MenuItem, { sx: { gap: "10px", }, onClick: function () { return itemClick(item.onClick); }, disableRipple: true, children: [item.icon, item.label] }, index));
            }) }) }));
}
